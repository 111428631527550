import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	grid: {
		display: 'grid',
		gridTemplateRows: 'auto',
		gridTemplateColumns: 'repeat(3, 218px)',
		gridColumnGap: theme.spacing.small,
		gridRowGap: theme.spacing.small,
		overflowY: 'auto',
		paddingBottom: theme.spacing.small,

		[getFromBreakpoint('md')]: {
			gridTemplateColumns: 'repeat(3, 288px)',
		},
	},
}));
