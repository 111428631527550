import { type FC, type ReactNode } from 'react';

import { useStyles } from './ImageGrid.styles';

type Props = {
	children?: ReactNode;
};

export const ImageGrid: FC<Props> = ({ children }) => {
	const { classes } = useStyles();

	return <div className={classes.grid}>{children}</div>;
};
